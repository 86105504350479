<template>
  <div id="chartwrap" style="width: 100%; height: 100%"></div>
</template>

<script>
import * as echarts from 'echarts'
var chart
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'count'
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  watch: {
    type() {
      this.dataList = this.config.data
      this.initChartData()
    },
    config: {
      handler() {
        this.dataList = this.config.data
        this.initChartData()
      }
    }
  },
  methods: {
    initChartData() {
      let yData = []
      let xData = []
      if (this.type === 'count') {
        yData = this.dataList.map((item) => {
          return item.name
        })
        xData = this.dataList.map((item) => {
          return item.count
        })
      }
      if (this.type === 'amount') {
        yData = this.dataList.map((item) => {
          return item.name
        })
        xData = this.dataList.map((item) => {
          return item.total
        })
      }

      chart = echarts.init(document.getElementById('chartwrap'), 'light')
      const option = {
        grid: {
          left: '0',
          top: '0',
          right: '20',
          bottom: '0',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: { show: false },
          axisLabel: { show: false },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: yData,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          showContent: true,
          formatter: (params) => {
            console.log('params', params)
            return `<div>${params[0].marker}${
              params[0].name
            }<span style="margin-left:10px;font-weight:600;">${Number(
              params[0].value
            )}</span></div>`
          }
        },
        series: {
          type: 'bar',
          data: xData,
          barWidth: 10,
          showBackground: true,
          colorBy: this.config.colors,
          backgroundStyle: {
            backgroundStyle: 'grey',
            borderColor: 'rgb(56, 60, 76)',
            borderWidth: 1,
            borderType: 'solid',
            borderRadius: [50, 50, 50, 50]
          },
          itemStyle: {
            normal: {
              borderRadius: 10
              // color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              //   {
              //     offset: 0,
              //     color: '#22B6ED'
              //   },
              //   {
              //     offset: 1,
              //     color: '#3FE279'
              //   }
              // ])
            }
          }
        }
      }
      chart.setOption(option)
    }
  }
}
</script>

<style>
</style>
