<template>
  <div class="left-chart-1">
    <div class="lc1-header">公物仓</div>
    <div class="lc1-details">
      {{
        type === 'count'
          ? '总数 ( 件 )'
          : '价值' + '( ' + config.amount.unit + ' )'
      }}<span class="value">{{
        type === 'count' ? config.count : config.amount.num
      }}</span>
      <div class="toggle-wrap">
        <span
          @click="changeType('count')"
          :class="{ 'span-active': activeIndex === '0', desc: true }"
          >数量</span
        >
        /
        <span
          @click="changeType('amount')"
          :class="{ 'span-active': activeIndex === '1', desc: true }"
          >价值</span
        >
      </div>
    </div>
    <!-- <dv-capsule-chart class="lc1-chart" :config="config" /> -->
    <capsule-chart :config="config" :type="type" ref="chart" />
    <dv-decoration-2 style="height: 10px; margin-top: 10px" />
  </div>
</template>

<script>
import capsuleChart from './capsule-chart.vue'
export default {
  name: 'LeftChart1',
  props: {
    warehouseList: {
      type: Array
    }
  },
  watch: {
    warehouseList() {
      this.init()
    }
  },
  components: {
    capsuleChart
  },
  data() {
    return {
      activeIndex: '0',
      type: 'count',
      config: {
        colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
        unit: '元'
      }
    }
  },
  methods: {
    changeType(type) {
      if (type === 'count') {
        this.activeIndex = '0'
        this.type = type
      }
      if (type === 'amount') {
        this.activeIndex = '1'
        this.type = type
      }
    },
    handelMoney(amount) {
      const AmountUnitlist = ['元', '万元', '亿元', '兆元']
      let strAmount = amount.toString()
      let amountUnit = ''
      AmountUnitlist.find((item, index) => {
        let newAmount = ''
        const strIndex = strAmount.indexOf('.')
        if (strIndex !== -1) {
          newAmount = strAmount.substring(0, strIndex)
        } else {
          newAmount = strAmount
        }
        if (newAmount.length < 5) {
          amountUnit = item
          return true
        } else {
          strAmount = ((newAmount * 1) / 10000).toString()
        }
      })
      const money = { num: 0, unit: '' }
      money.num = (strAmount * 1).toFixed(2)
      money.unit = amountUnit
      return money
    },
    init() {
      let count = 0
      let amount = 0
      this.warehouseList.forEach((item) => {
        count += item.count
      })
      this.warehouseList.forEach((item) => {
        amount += Number(item.total)
      })
      const amountObj = this.handelMoney(amount)
      this.config = {
        colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
        unit: '件',
        type: 'count'
      }
      this.config.count = count
      this.config.amount = amountObj
      this.config.data = this.warehouseList
    }
  }
}
</script>

<style lang="less">
.left-chart-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .lc1-header {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .lc1-details {
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 10px;

    .value {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      flex: 1;
    }
    .toggle-wrap {
      cursor: pointer;
      .span-active {
        color: #7bbcee;
      }
    }
  }

  .lc1-chart {
    flex: 1;
  }
}
</style>
